export default {
  setAuth: (state, auth = {}) => {
    state.auth = {
      ...auth,
    };
  },
  setTokenRefreshing: (state, tokenRefreshing) => {
    state.tokenRefreshing = tokenRefreshing;
  },
  setAuthenticated: (state, authenticated) => {
    state.authenticated = authenticated;
  },
  setJoinUser: (state, user) => {
    state.joinUser = user;
  },
  deleteJoinUser: (state) => {
    state.joinUser = null;
  },
  setCredentials: (state, credentials) => {
    state.credentials = credentials;
    state.credentialsExpireTime = credentials?.expiration ? new Date(credentials.expiration).getTime() : null;
  },
  setCredentialsPromise: (state, promise) => {
    state.credentialsPromise = promise;
  },
};
