import { useStore } from "@/store";

export async function getCredentials({ identityId, token, isAdmin = false }) {
  const store = useStore();

  if (store.getters["auth/isCredentialsValid"]()) {
    return store.getters["auth/getCredentials"];
  }

  if (store.getters["auth/getCredentialsPromise"]) {
    return store.getters["auth/getCredentialsPromise"];
  }

  const promise = (async () => {
    try {
      if (!isAdmin) {
        const result = await store.dispatch("auth/tokenRefresh");

        if (!result?.success) {
          throw new Error("token refresh 실패");
        }
      }

      await store.dispatch("users/getUserFromCognitoId", {
        setAccInfo: true,
        cognitoId: identityId.replace("ap-northeast-2:", ""),
        sigYn: false,
        accessKeyId: store.getters["auth/getAccessKeyId"],
      });

      return store.getters["auth/getCredentials"];;
    } catch (e) {
      console.log("Credentials 가져오기 실패:", e);
      store.dispatch("auth/signOut");
    } finally {
      store.commit("auth/setCredentialsPromise", null);
    }
  })();

  store.commit("auth/setCredentialsPromise", promise);
  return promise;
}
